'use client'
 
export default function Error({ reset }) {
  return (
    <div className="flex flex-col items-center justify-center py-20 px-4">
      <h2 style={{ fontSize: '2.5rem', fontWeight: 'bolder' }}>
        Something went wrong!
      </h2>
      <button 
        type="button" 
        className="mt-8 py-2 px-8 rounded-full" 
        onClick={() => reset()}
      >
        Try again
      </button>
    </div>
  )
}
